import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import setpointApolloClient from '@/lib/appsync/setpoint';
import getSetpointData from '@/graphql/setpoints/queries/getSetpointData.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'setpointModule',
  store,
})
class SetpointModule extends VuexModule {
  tasqSetpointData: any = {};

  @Mutation
  setSetpointDataForTasq(data): void {
    this.tasqSetpointData = data;
  }

  @Action
  async getSetpointDataForTasq({
   tasq,
   dataLength,
   wellName,
  }) {
    const fromTime = new Date(new Date().getTime() - dataLength * 86400000).toISOString().slice(0, -5);
    const  toTime = new Date().toISOString().slice(0, -5);

    try {
      const { data: { get_setpoint_rec: { dataset } } } = await setpointApolloClient.query({
        query: getSetpointData,
        variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          nodeid: wellName,
          from_time: fromTime,
          to_time: toTime,
          time_var: 'ModifiedTime',
          predictionid: tasq.id,
        },
      });
      const parsedDataset = jsonParse(dataset);
      const { plot } = parsedDataset[0];
      const setpointData = {} as any;
      setpointData.labels = plot.dataset['Tubing Pressure'];
      setpointData.casing = plot.dataset['Casing Pressure'];
      setpointData.static = plot.dataset['Static Pressure'];
      setpointData.loadFactor = plot.dataset['Load Factor'];
      [, setpointData.mainData] = plot.dataset.Value;
      [,, setpointData.lowerData] = plot.dataset.Value;
      [setpointData.upperData] = plot.dataset.Value;
      setpointData.currentPoint = plot.dataset.Current;
      setpointData.recommendedPoint = plot.dataset.Recommend;
      setpointData.uplift = (plot.dataset.perc_uplift * 100).toFixed();

      this.setSetpointDataForTasq(setpointData);
    } catch (e) {
      console.error(e);
    }
  }
}

export default getModule(SetpointModule);
